<template>
  <auth-container>
    <v-col justify="center" slot="right">
      <v-row justify="center">
        <v-img max-width="147" max-height="67" src="../../assets/Buro.svg"></v-img>
      </v-row>
      <v-row justify="center">
        <v-card class="mt-4 px-6 pb-4" outlined width="340">
          <v-form @submit.prevent="login" class="mt-4 pa-2">
            <v-text-field
              @blur="$v.email.$touch"
              required
              :readonly="readonlyEmail"
              :error-messages="emailErrorMessages"
              v-model="email"
              name="email"
              label="Email Address"
            ></v-text-field>

            <v-text-field
              v-model="password"
              class="ma-0 pa-0"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              name="pass"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              @blur="$v.password.$touch"
              @click:append="showPassword = !showPassword"
              required
              :error-messages="passwordErrorMessages"
            ></v-text-field>
            <transition name="slide-fade">
              <v-alert v-if="hasError" type="error" class="caption pa-1 mt-4">
                {{
                this.errorMessage
                }}
              </v-alert>
            </transition>
            <bz-submit-button
              :loading="loading"
              :disabled="this.$v.$invalid"
              title="Login"
              icon="mdi-account"
              type="Submit"
              class="full-width mt-4"
            >
              <v-divider vertical color="white" class="ml-2" />
            </bz-submit-button>
            <div class="mt-4 d-flex flex-column align-start">
              <auth-link-button title="Forgot Password?" :to="{ name: 'forgot-password' }" />
              <auth-link-button title="Not a member? Signup" :to="{ name: 'sign-up' }" />
            </div>
          </v-form>
        </v-card>
      </v-row>
    </v-col>
  </auth-container>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import AuthContainer from "../components/AuthContainer";
import BzSubmitButton from "@shared/components/BzSubmitButton";
import AuthLinkButton from "../components/AuthLinkButton";
import { roles } from "@/shared/constants/roles";

export default {
  name: "LoginCard",
  components: {
    AuthContainer,
    BzSubmitButton,
    AuthLinkButton
  },
  data() {
    return {
      svgPath: "../../assets/submit_icon.svg",
      email: "",
      password: "",
      errorMessage: "",
      loading: false,
      showPassword: false,
      readonlyEmail: false
    };
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(5)
    }
  },
  computed: {
    hasError() {
      return this.errorMessage && this.errorMessage.length;
    },
    emailErrorMessages() {
      const errors = [];
      if (!this.$v.email.$dirty) return [];
      !this.$v.email.required && errors.push("Email is required.");
      !this.$v.email.email && errors.push("Please enter a valid email address");
      return errors;
    },
    passwordErrorMessages() {
      const errors = [];
      if (!this.$v.password.$dirty) return [];
      !this.$v.password.required && errors.push("Password is required");
      !this.$v.password.minLength &&
        errors.push("Password must be at least 5 characters long");
      return errors;
    }
  },
  methods: {
    ...mapActions("auth", {
      loginUser: "loginUser"
    }),
    async login() {
      this.loading = true;
      try {
        const user = await this.loginUser({
          email: this.email,
          password: this.password
        });
        let accountRoles = [];
        for (var i in user.roles) {
          accountRoles.push(user.roles[i].resourceGroups[0].name);
        }
        if (accountRoles.includes(roles.ACCOUNT_OWNER)) {
          this.$router.replace({ name: "admin" });
        } else if (accountRoles.includes(roles.ONBOARDING_MODULE_MEMBER)) {
          this.$router.replace({ name: "user-home" });
        } else if (accountRoles.includes(roles.COURSE_EVALUATOR)) {
          this.$router.replace({ name: "evaluator-home" });
        }
      } catch (error) {
        this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = "";
        }, 2000);
      } finally {
        this.loading = false;
        this.password = "";
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  },
  beforeMount() {
    this.email = this.$route.query.email;
    if (this.email) {
      this.readonlyEmail = true;
    }
  }
};
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
</style>
